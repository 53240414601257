// Documentation: https://github.com/jakearchibald/idb
import { IDBPDatabase, openDB } from "idb";
import { TIndexedDbName, TIndexedDbStore } from "./base-indexed-db-types";

export abstract class BaseIndexedDb<R, W> {
    private _dbVersion: number = 1; // При создании нового стора в существующей базе необходимо передать в конструктор новую версию DB

    protected abstract readDb(key?: string): Promise<R>;
    protected abstract writeDb(data: W, id?: string): Promise<void>;
    protected showDbError?(): void

    protected constructor(
            protected readonly dbName: TIndexedDbName,
            protected readonly storeName: string,
            protected readonly keyPath: string,
            dbVersion?: number
    ) {
        if (dbVersion) {
            this._dbVersion = dbVersion;
        }
    }

    public async openDb(): Promise<TIndexedDbStore> {
        let db = await this._openDb();
        let transaction = db.transaction(this.storeName, "readwrite");
        return transaction.objectStore(this.storeName);
    }

    private async _openDb(): Promise<IDBPDatabase> {
        let self = this;
        let db: IDBPDatabase = await openDB(this.dbName, this._dbVersion, {
            upgrade(db: IDBPDatabase) {
                if (!db.objectStoreNames.contains(self.storeName)) {
                    db.createObjectStore(self.storeName, { keyPath: self.keyPath })
                }
            },
            blocked() {
                db.close();
                self.showDbError && self.showDbError();
            },
        });
        return db;
    }
}
